import { createApp, watch } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import translations from './translations'

// Retrieve the saved language from localStorage or fall back to 'en'
const savedLocale = localStorage.getItem('locale') || 'en'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: savedLocale,  // Load saved locale or use 'en' as default
  fallbackLocale: 'en',
  messages: translations
})

const app = createApp(App)

// Watch for changes in the locale and update the body attributes and localStorage
watch(
  () => i18n.global.locale.value,
  (newLocale) => {
    // Save the new locale to localStorage
    localStorage.setItem('locale', newLocale)

    // Update body direction and class based on the new locale
    if (newLocale === 'ar') {
      document.body.setAttribute('dir', 'rtl')
      document.body.classList.add('ar')
    } else {
      document.body.setAttribute('dir', 'ltr')
      document.body.classList.remove('ar')
    }
  },
  { immediate: true } // Trigger the watcher immediately to set the correct direction on page load
)

// Initialize the app
app.use(i18n).use(router).mount('#app')
