// LanguageSwitcher.vue
<template>
  <div style="position: absolute;height: 100%;opacity: 0;">
    <select v-model="$i18n.locale" style="width: 100%;height: 100%;">
      <option value="en">English</option>
      <option value="ar">Arabic</option>
      <option value="fr">French</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher'
}
</script>