<template>
    
    <div class="container" style="text-align: left; margin-top: 100px;display: flex;flex-direction: column;gap: 16px;">
        <h2>Information Collection and Privacy Policy of FenTec Mobility</h2>
    
        <h3>1. Introduction to FenTec Mobility and Its Services</h3>
        <p>FenTec Mobility is a shared mobility platform in Algeria offering environmentally friendly electric vehicles, specifically electric kick-scooters. Through the FenTec Mobility mobile application, users can:</p>
        <ul>
            <li>Create a personal user account.</li>
            <li>Recharge their e-wallet.</li>
            <li>Search for the nearest e-scooter using Google Maps.</li>
            <li>Scan the QR code of the selected e-scooter.</li>
            <li>Unplug the headset and safety cable.</li>
        </ul>
        <p>After using the e-scooter, users can take a photo to ensure it is properly parked without disturbing pedestrians.</p>
    
        <h3>2. Company Information</h3>
        <p><strong>Company:</strong> FenTec Mobility</p>
        <p><strong>Address:</strong> 29 street “P”, City of Riyadh, Batna 05010, Algeria.</p>
        <p><strong>Commercial Register Number:</strong> 24A6114552</p>
        <p><strong>Tax Identification Number (NIF):</strong> 1950501067800500</p>
        <p><strong>Statistical Identification Number (NIS):</strong> 1 995 0501 06700 39</p>
        <p><strong>Email:</strong> <a href="mailto:Contact@fentecmobility.com">Contact@fentecmobility.com</a></p>
        <p><strong>Customer Service:</strong> +213 557201134</p>
    
        <h3>3. Types of Information Collected</h3>
        <p>In compliance with Algerian law 18-07 of 10-06-2018 on personal data protection, FenTec Mobility collects various personal data when you use their services:</p>
        <p><strong>Creating a FenTec Mobility Account:</strong></p>
        <ul>
            <li>First and last name</li>
            <li>Date of birth</li>
            <li>Telephone number</li>
            <li>Email address</li>
            <li>Photo of an identity document</li>
            <li>Personal photo</li>
        </ul>
        <p><strong>Creating a FenTecPay Account:</strong></p>
        <ul>
            <li>First and last name</li>
            <li>Telephone number</li>
            <li>Email address</li>
            <li>Photo of an identity document</li>
            <li>Personal photo</li>
        </ul>
        <p><strong>Linking Third-Party Services:</strong></p>
        <ul>
            <li>Profile information from third-party services (e.g., Facebook, Google)</li>
        </ul>
        <p><strong>Location Data:</strong></p>
        <ul>
            <li>Location of your mobile device for showing nearby e-scooters</li>
            <li>Location data from FenTec Mobility vehicles during rides</li>
        </ul>
        <p><strong>Technical Information:</strong></p>
        <ul>
            <li>IP address</li>
            <li>Device token</li>
            <li>Cookies and similar tracking technologies</li>
        </ul>
        <p><strong>Shared Information:</strong></p>
        <ul>
            <li>Information shared on services like social media comments or blog entries</li>
        </ul>
        <p><strong>Third-Party Information:</strong></p>
        <ul>
            <li>Information from marketing and advertising partners</li>
        </ul>
    
        <h3>4. Use of Collected Information</h3>
        <p>The information collected by FenTec Mobility is used for:</p>
        <ul>
            <li>Managing user accounts and ride history</li>
            <li>Verifying user identity and age</li>
            <li>Ensuring user safety (e.g., helmet use)</li>
            <li>Optimizing and improving services</li>
            <li>Detecting and preventing policy violations or illegal activities</li>
            <li>Serving personalized ads on third-party platforms</li>
        </ul>
        <p>FenTec Mobility does not sell personal information to third parties for direct marketing purposes.</p>
    
        <h3>5. Retention of Information</h3>
        <p>FenTec Mobility retains your information for as long as necessary to provide services or until your account is deleted. Some information may be retained longer for legal obligations, fraud prevention, fee collection, or to enforce terms of use.</p>
    
        <h3>6. Responsibility for Information</h3>
        <p>For concerns about how your personal information is processed, contact FenTec Mobility at <a href="mailto:Contact@fentecmobility.com">Contact@fentecmobility.com</a> or file a complaint with your local data protection authority.</p>
    </div>
    <footer>
      <div class="content">
        <p style="color: #ffe5cf;margin-bottom: -10px;" class="mobile-footer-paragraoh">Go safely, Freely and Enjoyably <br/>
          <span style="font-size: 14px;">Together Towards a Sustainable World.</span>
        </p>
        <div>
            
            <a href="">
                Terms and conditions<br>
                Service agreement<br>
            </a>
            <a href="/Privacy-Police">
                Privacy police
            </a>
        </div>
        <div class="links">
          <!-- <a href="">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" />
              <path d="M10 9l5 3l-5 3z" />
            </svg>
          </a> -->
          <a href="https://www.facebook.com/profile.php?id=61564285140098&mibextid=qi2Omg&rdid=VoFkZZFPPZnTyg15&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FJhifFbsE52pYV6mz%2F%3Fmibextid%3Dqi2Omg" target="_blanck">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
            </svg>
          </a>
          <a href="https://www.instagram.com/fentec_mobility?igsh=MTN5MXlndHVoODd3cg%3D%3D" target="_blanck">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
              <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M16.5 7.5l0 .01" />
            </svg>
          </a>
          <!-- <a href="">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
              <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
            </svg>
          </a> -->
          <a href="https://www.tiktok.com/@fentec.mobility?_t=8p4B2G0PBdy&_r=1" target="_blanck">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tiktok" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M21 7.917v4.034a9.948 9.948 0 0 1 -5 -1.951v4.5a6.5 6.5 0 1 1 -8 -6.326v4.326a2.5 2.5 0 1 0 4 2v-11.5h4.083a6.005 6.005 0 0 0 4.917 4.917z" />
            </svg>
          </a>
        </div>
        <div class="btns">
          <a href=""><img src="/imgs/app.png?v=1.0" alt="App store"></a>
          <a href="http://fentecmobility.com/fentec.apk" download ><img src="/imgs/play_store.png?v=1.0" alt="Play store"></a>
        </div>
      </div>
      <div class="img">
        <img src="/imgs/footer-img.png?v=1.0" alt="">
        <p>Go safely, Freely and Enjoyably <br/>
          <span style="font-size: 16px;">Together Towards a Sustainable World.</span>
        </p>
      </div>
  </footer>

</template>
  
  <script>
  
  export default {
    name: 'PrivacyView',
  }
  </script>
  