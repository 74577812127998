<template>
    
    <section style="margin-top: 100px;text-align: right;" class="container" dir="rtl">
    <h2>طلب حذف البيانات أو الحساب</h2>
    <p>نحن نحترم خصوصيتك ونلتزم بحماية بياناتك الشخصية. إذا كنت ترغب في حذف بياناتك الشخصية أو إغلاق حسابك لدى <strong>FenTec Mobility</strong>، يُرجى اتباع الخطوات التالية:</p>

    <h3>عبر البريد الإلكتروني:</h3>
    <ul>
      <li>يُرجى إرسال رسالة بريد إلكتروني إلى <a href="mailto:Contact@fentecmobility.com">Contact@fentecmobility.com</a>، وذكر "طلب حذف البيانات" في عنوان الرسالة.</li>
      <li>في نص الرسالة، تأكد من تضمين معلومات الحساب مثل اسم المستخدم أو عنوان البريد الإلكتروني المرتبط بالحساب.</li>
      <li>سنقوم بمعالجة طلبك في غضون <strong>7</strong> أيام عمل، وستتلقى تأكيدًا عبر البريد الإلكتروني عند إتمام العملية.</li>
    </ul>

    <h3>عبر الهاتف:</h3>
    <ul>
      <li>يمكنك الاتصال بخدمة العملاء لدينا على الرقم <strong>0660980645</strong>.</li>
      <li>عند الاتصال، يُرجى إبلاغ الممثل برغبتك في حذف بياناتك أو حسابك، وتوفير معلومات الحساب اللازمة للتحقق.</li>
      <li>سيقوم فريقنا بمساعدتك في معالجة طلبك.</li>
    </ul>

    <p><strong>ملاحظة:</strong> بعد حذف بياناتك أو حسابك، لن تتمكن من استعادتها. إذا كنت تحتاج إلى مزيد من المساعدة، لا تتردد في التواصل معنا.</p>
  </section>
    <footer>
      <div class="content">
        <p style="color: #ffe5cf;margin-bottom: -10px;" class="mobile-footer-paragraoh">Go safely, Freely and Enjoyably <br/>
          <span style="font-size: 14px;">Together Towards a Sustainable World.</span>
        </p>
        <div>
            
            <a href="">
                Terms and conditions<br>
                Service agreement<br>
            </a>
            <a href="/Privacy-Police">
                Privacy police
            </a>
        </div>
        <div class="links">
          <!-- <a href="">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" />
              <path d="M10 9l5 3l-5 3z" />
            </svg>
          </a> -->
          <a href="https://www.facebook.com/profile.php?id=61564285140098&mibextid=qi2Omg&rdid=VoFkZZFPPZnTyg15&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FJhifFbsE52pYV6mz%2F%3Fmibextid%3Dqi2Omg" target="_blanck">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
            </svg>
          </a>
          <a href="https://www.instagram.com/fentec_mobility?igsh=MTN5MXlndHVoODd3cg%3D%3D" target="_blanck">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
              <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M16.5 7.5l0 .01" />
            </svg>
          </a>
          <!-- <a href="">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
              <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
            </svg>
          </a> -->
          <a href="https://www.tiktok.com/@fentec.mobility?_t=8p4B2G0PBdy&_r=1" target="_blanck">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tiktok" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M21 7.917v4.034a9.948 9.948 0 0 1 -5 -1.951v4.5a6.5 6.5 0 1 1 -8 -6.326v4.326a2.5 2.5 0 1 0 4 2v-11.5h4.083a6.005 6.005 0 0 0 4.917 4.917z" />
            </svg>
          </a>
        </div>
        <div class="btns">
          <a href=""><img src="/imgs/app.png?v=1.0" alt="App store"></a>
          <a href="http://fentecmobility.com/fentec.apk" download ><img src="/imgs/play_store.png?v=1.0" alt="Play store"></a>
        </div>
      </div>
      <div class="img">
        <img src="/imgs/footer-img.png?v=1.0" alt="">
        <p>Go safely, Freely and Enjoyably <br/>
          <span style="font-size: 16px;">Together Towards a Sustainable World.</span>
        </p>
      </div>
  </footer>

</template>
  
  <script>
  
  export default {
    name: 'DeleteAccountView',
  }
  </script>
  